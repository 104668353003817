* {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

button:focus, a:focus, .btn {outline:0 !important;}
button, .btn {outline: none !important;}

.search-results-listings .main-for-item div.scrollable-content > div:nth-child(odd) {
  background: #FFFFFF !important;
}
.search-results-listings .main-for-item div.scrollable-content > div:nth-child(1){
  border-top: none !important;
}

/*.search-results .main-for-item div.scrollable-content > div:nth-child(odd) {
  background-color:#FAFAFA;
}*/

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .todays-deals-purple,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .todays-deals-purple {
   width: 130px;
   top:28px;
   height:38px;
   left: 9999px;
   padding: 8px 12px;
   background-color: #1A1A1A;
   border-radius: 4px;
   color: #FFF;
   font-size: 14px;
   line-height: 20px;
   text-align: center;
   -webkit-box-shadow: none;
   -moz-box-shadow: none;
   box-shadow: none;
 }

 .displayItemMoq {
   left:-30px !important;
 }

 .itemMoqTooltip, .itemMxqTooltip {
  opacity: 1 !important;
  display: block;
  pointer-events: auto !important;
  -webkit-transform: translate(-50%, 20px) !important;
  transform: translate(-50%, 20px) !important;
}

.wrapper .listing-moq-tt, .wrapper .listing-mxq-tt {
  white-space: nowrap;
  background-color: #1A1A1A;
  top: -66px;
  color: #fff;
  display: block;
  left: 50% !important;
  margin-bottom: 15px;
  opacity: 0!important;
  padding: 8px 21px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  border-radius: 4px;
  z-index: 2000;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  visibility: hidden;
}

.wrapper .listing-moq-tt.displayItemMoq, .wrapper .listing-mxq-tt.displayItemMxq {
  opacity: 1 !important;
  visibility: visible;
}


.wrapper .listing-moq-tt:before, .wrapper .listing-mxq-tt:before {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1A1A1A 10px;
  bottom: -9px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0;
  z-index: 2;
}
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.availability-tooltip.showAvailable,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.exwTooltip.showExw,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.itemMoqTooltip.displayItemMoq,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.itemMxqTooltip.displayItemMxq,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.itemUntPriceTooltip.displayUnitPrice,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.availability-tooltip.showAvailable,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.exwTooltip.showExw,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.itemMoqTooltip.displayItemMoq,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.itemMxqTooltip.displayItemMxq,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.itemUntPriceTooltip.displayUnitPrice
 {
    top:23px;
    bottom: auto;
 }

 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper.item-qty .tooltip.itemMxqTooltip.displayItemMxq,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper.item-qty .tooltip.itemMxqTooltip.displayItemMxq {
   margin-top: 5px;
 }

 .purchases-items-wrapper div.scrollable-content > div:nth-child(1) .wrapper .listing-moq-tt,
 .purchases-items-wrapper div.scrollable-content > div:nth-child(1) .wrapper .listing-mxq-tt,
 .notification-center-wrapper div.notification-items-wrapper > div:nth-child(1) .wrapper .listing-moq-tt,
 .notification-center-wrapper div.notification-items-wrapper > div:nth-child(1) .wrapper .listing-mxq-tt
 {
    top:14px;
    bottom: auto;
 }

 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.availability-tooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.exwTooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.itemMoqTooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.itemMxqTooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(1) .wrapper .tooltip.itemUntPriceTooltip:before,
 .purchases-items-wrapper div.scrollable-content > div:nth-child(1) .wrapper .itemMoqTooltip.listing-moq-tt:before,
 .purchases-items-wrapper div.scrollable-content > div:nth-child(1) .wrapper .itemMxqTooltip.listing-mxq-tt:before,
 .notification-center-wrapper div.notification-items-wrapper > div:nth-child(1) .listing-moq-tt:before,
 .notification-center-wrapper div.notification-items-wrapper > div:nth-child(1) .listing-mxq-tt:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.availability-tooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.exwTooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.itemMoqTooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.itemMxqTooltip:before,
 .search-results .main-for-item div.scrollable-content > div:nth-child(2) .wrapper .tooltip.itemUntPriceTooltip:before
 {
    border-top: solid transparent 10px !important;
    border-right: solid transparent 10px !important;
    border-bottom: solid  #1A1A1A 10px !important;
    top: -20px;
    content: "";
    height: 0;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    width: 0;
    z-index: 2;
 }

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.todays-deals-purple:after,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.todays-deals-purple:after {
  border-bottom-color: #493B8D;
  left: 50%;
  margin-left: -10px;
  z-index: 1;
}
.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.todays-deals-purple:before,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.todays-deals-purple:before {
  border-top: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #1A1A1A 10px;
  top: -20px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0;
  z-index: 2;
}

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .refurbished-purple,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .refurbished-purple {
  width: 180px;
  top:28px;
  bottom: auto;
  left: 9999px;
  padding: 8px 12px;
  background-color: #1A1A1A;
  border-radius: 4px;
  color: #FFF;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.refurbished-purple:after,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.refurbished-purple:after {
  border-bottom-color: #493B8D;
  left: 50%;
  margin-left: -10px;
  z-index: 1;
}
.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.refurbished-purple:before,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.refurbished-purple:before {
  border-top: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #1A1A1A 10px;
  top: -20px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0;
  z-index: 2;
}

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .price-drop-green,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .price-drop-green {
  width: 100px;
  top:28px;
  /*bottom:-80px;*/
  height:38px;
  /*left: -39px;*/
  left: 9999px;
  padding: 8px 12px;
  background: #79BD5A;
  /*border: 1px solid rgba(51,51,51,0.30);*/
  border-radius: 4px;
  color: #FFF;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.price-drop-green:after,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.price-drop-green:after {
  border-bottom-color: #79BD5A;
  left: 50%;
  margin-left: -10px;
  z-index: 1;
}
.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.price-drop-green:before,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.price-drop-green:before {
  border-top: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #79BD5A 10px;
  top: -20px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0;
  z-index: 2;
}


.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .just-launched-red,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .just-launched-red {
  width: 120px;
  top:28px !important;
  /*bottom:-80px;*/
  height:38px;
  /*left: -39px;*/
  left: 9999px;
  padding: 8px 12px;
  background: #EB3B44;
  /*border: 1px solid rgba(51,51,51,0.30);*/
  border-radius: 4px;
  color: #FFF;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.just-launched-red:after,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.just-launched-red:after {
  border-bottom-color: #EB3B44;
  left: 50%;
  margin-left: -10px;
  z-index: 1;
}
.search-results .main-for-item div.scrollable-content > div:nth-child(1) .container-fluid > div > div > div .item-details .tooltip.just-launched-red:before,
.search-results .main-for-item div.scrollable-content > div:nth-child(2) .container-fluid > div > div > div .item-details .tooltip.just-launched-red:before {
  border-top: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #EB3B44 10px;
  top: -20px;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0;
  z-index: 2;
}

.search-results .main-for-item div.scrollable-content > div:nth-child(odd) .container-fluid > div > div > div .item-details {
  background-color:#F6F6F6 !important;
}
.search-results .main-for-item div.scrollable-content > div:nth-child(even) .container-fluid > div > div > div .item-details {
  background-color:#F6F6F6 !important;
}
.search-results .main-for-item div.scrollable-content > div:nth-child(1){
  border-top: none;
}

.btn-margin {
  margin-top: 7px;
}

a:hover {
  cursor:pointer;
}

#clear {
  clear:both;
}

.loader {
  /*Remove loading spinner*/
  display: none;
  position: absolute;
  top:50%;
  left:50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 99999;
  background: url(assets/images/spinner-blue.gif) no-repeat transparent center center;
  background-size: cover;
}

.loader-red{
  /*Remove loading spinner*/
  display: none;
  position: absolute;
  top:50%;
  left:50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 99999;
  background: url(assets/images/spinner-red.gif) no-repeat transparent center center;
  background-size: cover;
}

.loader-btn {
  width: 16px;
  height: 16px;
  margin-left:12px;
  margin-top:1px;
  -webkit-animation: spin 0.50s linear infinite;
  animation: spin 0.50s linear infinite;
  z-index: 100;
  display: block;
  background: url(assets/images/spinner.svg) no-repeat transparent center center;
  background-size: cover;
  float:left;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input:focus, select:focus, textarea:focus, form:focus, button:focus {
  outline:0;
}

input::-webkit-calendar-picker-indicator {
  opacity: 1;
}


.items:nth-child(odd) {
  background:#FBFBFB;
}

div > app-pre-cart-child:nth-child(odd) .ok {
  background:#FBFBFB;
}

.sticky {
position: -webkit-sticky;
position: sticky;
top: 0;
}

.qty-dd-wrap {
min-width:54px;
max-width:54px;
position:auto;
margin:0 auto;
}

.qty-dd-wrap:nth-child(odd) {
  background:#FBFBFB;
}



.btn-action {
min-width:200px;
max-width:200px;
min-height:40px;
background:#FF5757;
font-size:16px;
color:#FFFFFF !important;
border-radius:100px;
display: inline-block;
text-align:center;
font-weight: 600;
border:#FF5757 thin solid;
border-width:2px;
}

.btn-action:hover {
background:#FF5757;
}

.btn-action .label {
padding-top:6px;
display: block;
}

.btn-call {
min-width:200px;
max-width:200px;
min-height:40px;
background:#FFFFFF;
font-size:16px;
color:#493B8D !important;
border-radius:100px;
display: inline-block;
text-align:center;
font-weight: 600;
border:#493B8D thin solid;
border-width:2px;
}

a.btn-call, a.btn-action {
text-decoration:none;
}

.btn-call .label {
padding-top:6px;
display: block;
color:#493B8D !important;
}

a.add-to-cart {
padding:2px 0px 4px 0px;
border:#FF5757 thin solid;
border-width:1px;
-webkit-border-radius: 100px;
-moz-border-radius: 100px;
border-radius: 100px;
margin:15px auto 0 auto !important;
margin:0 auto 0 auto;
display: block;
font-size: .85714286em;
font-weight: 600;
color:#FF5757 !important;
min-width:120px;
max-width:120px;
min-height:24px;
max-height:24px;
}

a.add-to-cart span {
  padding-right:11px;
}

a.add-to-cart:hover {
text-decoration: none;
background:#FF5757;
color:#FFFFFF !important;
}

a.add-to-cart svg {
float:left;
margin-left:12px;
margin-top:1px;
}

a.add-to-cart svg #add-to-cart {
fill:#FF5757;
}

a.add-to-cart:hover > svg #add-to-cart {
fill:#FFFFFF;
}



/* Add to cart span */
span.disabled {
  padding:2px 0px 4px 0px;
  border:#333333 thin solid;
  border-width:1px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  margin:15px auto 0 auto;
  display: block;
  font-size: .85714286em;
  font-weight: 600;
  color:#333333 !important;
  min-width:120px;
  max-width:120px;
  min-height:24px;
  max-height:24px;
  text-align: center;
  opacity:.3;
}


a.edit-qty {
padding:2px 0px 4px 0px;
border:#FF5757 thin solid;
border-width:1px;
-webkit-border-radius: 100px;
-moz-border-radius: 100px;
border-radius: 100px;
margin:15px auto 0 auto !important;
margin:0 auto 0 auto;
display: block;
font-size: .85714286em;
font-weight: 600;
color:#FFFFFF !important;
min-width:120px;
max-width:120px;
min-height:24px;
max-height:24px;
background:#FF5757;
}

a.edit-qty:hover {
color:#FFFFFF !important;
}

a.edit-qty svg {
float:left;
margin-left:12px;
margin-top:1px;
}

a.edit-qty svg #edit-qty {
fill:#FFFFFF;
}
/*** END Item Buttons <---***/

/***--> Export Excel Button ***/
.excel-btn {
text-align:right;
padding-top:0px;
float: right;
color:#FFFFFF;
}


.excel-btn button {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  padding:2px 0px 0px 7px;
  font-size:10px;
  background:#493B8D;
  color:#FFFFFF;
  width:94px;
  height:24px;
  display: block;
  float: right;
  font-family:'HelveticaNeueCyr-bold';
  position:absolute;
  top:6px;
  right:30px;
  cursor: pointer;
  border:none;
  }

  .excel-btn .float-right {
    background:#FFFFFF;
    max-width:155px;
    min-height:100%;
    padding-top:3px;
  }

  .excel-btn button span {
    float:left;
    padding-top:0px;
    padding-left:16px;
    padding-right:3px;
  }


  .excel-btn button:disabled {
    opacity:0.5;
    cursor: context-menu;
  }

.excel-btn a:hover {
  text-decoration:none;
  background:#504199;
}

.excel-btn button:hover {
  text-decoration:none;
  background:#504199;
}

.excel-btn svg {
  display:block;
  margin-top:-1px;
  width:20px;
  height:20px;
}

.excel-btn svg #excel {
  fill:#FFFFFF;
}
/*** END Export Excel Button <---***/

.svg-helper-flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  display:block;
}

.flipped-img {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

/* HUBX Radio */
.hubx-radio {
  display: none;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.hubx-radio + label {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background-image: url("assets/images/icons/payment-type-off-copy-2.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}
.hubx-radio:checked + label {
  background-image: url("assets/images/icons/payment-type-on.svg");
}

/* HUBX Alert  buttons*/
.btn-alert-confirm{
  font-family: HelveticaNeueCyr-Bold;
  text-align: center;
  cursor: pointer;
  min-width: 220px;
  max-width: 186px;
  margin: 0 auto 1.25em auto;
  background: #493B8D;
  font-size: 22px;
  color: #FFFFFF;
  border: 0;
  border-radius: 100px;
  padding: 10px 0px 10px 0px;
  line-height: 20px;
}
.btn-alert-confirm:hover{
    background: #5B4BA5;
}

/* --------------------------- --->> Custom Alert */
.animated {
  animation-duration: 0.2s !important;
  animation-fill-mode: both;
}

.reset-pwd-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 0;
  padding-left: 0;
  background: rgba(52, 54, 66, 0.5);
  z-index: 99999999;
  overflow-y: auto;
  -webkit-transition: opacity 0.3s 0, visibility 0 0.3s;
  -moz-transition: opacity 0.3s 0, visibility 0 0.3s;
  transition: opacity 0.3s 0, visibility 0 0.3s;
}

.reset-pwd-modal.zero-qty-alert .add-to-wrap {
  position: relative;
  box-shadow: 0 4px 12px 0 rgba(44,56,97,0.30);
  width: 100%;
  max-width: 424px;
  min-height: 300px;
  max-height: 300px;
  background: #FFF;
  margin: 161px auto;
  cursor: auto;
  border-radius: 4px;
  padding:12px 48px 14px 48px;
}

.reset-pwd-modal.zero-qty-alert .modal-body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height:105px;
  font-size: 14px;
}

.reset-pwd-modal.split-modal .add-to-wrap {
  position: relative;
  box-shadow: 0 4px 12px 0 rgba(44,56,97,0.30);
  width: 100%;
  max-width: 424px;
  min-height: 314px;
  max-height: 314px;
  background: #FFF;
  margin: 161px auto;
  cursor: auto;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  padding:12px 48px 14px 48px;
}

.reset-pwd-modal .add-to-wrap .modal-title {
  font-family: HelveticaNeueCyr-Bold;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
}

.reset-pwd-modal .add-to-wrap .title-icon {
  margin-right:20px;
}

.reset-pwd-modal .add-to-wrap .close {
  width:24px;
  height:24px;
  background:url(/assets/images/close.svg) no-repeat;
  display: block;
  cursor:pointer;
  padding: 0;
  margin: 0;
  position: absolute;
  top:15px;
  right:-20px;
}

.reset-pwd-modal .modal-header{
  display: block;
  border: 0;
  /*padding: 26px 28px 26px 48px;*/
  padding:16px 0 31px 0;
  position: relative;
  line-height: 20px;
}
.reset-pwd-modal .modal-header img {
  margin-left:-4px;
}
.reset-pwd-modal .modal-header h4{
  font-family: HelveticaNeueCyr-Bold;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
}
.reset-pwd-modal .modal-header .close{
  opacity: 1;
}
.reset-pwd-modal .modal-body{
  padding:10px 40px 10px 40px;
  background: rgba(224,224,224,0.15);
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
}
.reset-pwd-modal .modal-content{
  border: 0;
  border-radius: 0;
}
.reset-pwd-modal input[type=radio]~label {
  height: 24px;
  width: 24px;
}
.reset-pwd-modal input[type=radio]:checked+label {
  height: 24px;
  width: 24px;
}
.reset-pwd-modal .modal-header{
  padding: 16px;
}
.reset-pwd-modal .modal-header h4{
  font-family: HelveticaNeueCyr-Bold;
  font-size: 12px;
}
.reset-pwd-modal .address-title {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
}
.reset-pwd-modal .modal-address-radio{
  min-height: 88px;
  max-height: 88px;
  padding: 10px 16px 14px;
  font-size: 12px;
}
.reset-pwd-modal .modal-footer{
  padding: 12px 16px;
  border:none;
  line-height: 20px;
}
.reset-pwd-modal .btn.btn-modal{
  min-width: 120px;
  padding: 9px 33px 8px;
  font-size: 12px;
  line-height: 12px;
}

.reset-pwd-modal .btn.btn-modal.btn-modal-confirm {
  margin:20px auto 0 auto;
  min-width:132px;
  max-width:132px;
  background-image: linear-gradient(225deg, #519DFA 0%, #2070D2 100%);
  border-radius: 100px;
  font-size:16px;
  letter-spacing: 0;
  text-align:center;
  color:#FFFFFF;
  font-family: 'HelveticaNeueCyr-Bold';
  cursor:pointer;
}

/* TOOLTIP ADJUSTMENTS */

ngx-intl-tel-input, .intl-tel-input {
  width:100% !important;
}

.input-group .custom-phone {
    height: 48px;
    width: 100%;
    border: 1px solid #7f75b2;
    border-radius: 4px;
    color: #FFFFFF;
    background: #493B8D;
    background-color: #493B8D;
    outline: none;
    box-shadow: none;
    font-size: 18px;
}

.input-group .intl-tel-input.allow-dropdown .flag-container{
  left: 1px;
}

.input-group .custom-phone::placeholder {
  font-size: 18px;
  color: #7f75b2;
  letter-spacing: 0;
}

.input-group .custom-phone:-webkit-autofill {
    border: 1px solid #7f75b2 !important;
    -webkit-box-shadow: inset 0 0 0 50px #493B8D !important;
    -webkit-text-fill-color: #FFFFFF !important;
    color: #FFFFFF !important;
}

.selected-flag {
  padding:0 8px 0 10px !important;
  background:#493B8D !important;
}

.iti-arrow {
  border-top:4px solid #FFFFFF !important;
}

.iti-flag {
  box-shadow: 0px 0px 1px 0px #FFF;
}

.intl-tel-input {
  width:100% !important;
}


/*owl*/
.checkout-boxes .owl-carousel .owl-stage{
  left: -30px;
}
.owl-carousel .owl-stage{
  display: flex;
}
.owl-carousel .owl-item{
  display: flex;
}


.disabled .item__logo, .disabled .details, .disabled .item__description, .disabled .price, .disabled .item__main--right, .disabled .moq, .disabled img, .disabled .item__qty, .disabled .price-drop-svg, .disabled .just-launched-svg, .disabled .item__mobile--price {
  opacity:.3;
}

input[type=number] {
  -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

_:-ms-lang(x), .item-det-ie-fix {
  /* width: 92% !important;  */
}

_:-ms-lang(x), .item__cart > .row {
  float:none !important;
}

_:-ms-lang(x), .item__cart {
  width:220px !important;
  max-width: 220px !important;
}


/* PO DATEPICKER FIX */
bs-datepicker-container, bs-daterangepicker-container {
  z-index: 10000!important;
}

/* PO DATEPICKER STYLE */
bs-daterangepicker-container {
  top: 43px !important;
  left: -48px!important;
}

bs-daterangepicker-inline-container {
  display: block !important;
  position: absolute !important;
  z-index: 10000!important;
  top: 0;
  left: 156px !important;
}

.custom-phone {
  width:288px;
}

/* PAGINATION STYLE */
.pagination {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 3px;
    list-style: none;
    border-radius: .25rem;
    justify-content: center;
}

/*.theme-blue .bs-datepicker-head {
  background:#493B8D !important;
}

.theme-blue .bs-datepicker-body table td span.selected, .theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color:#493B8D !important;
}*/

.content-right .form-control input {
  font-size:14px !important;
}

.app-po-line:nth-child(even) .po-line-content {
  background:#f1f1f1 !important;
  border-left:6px #f1f1f1 solid;
  border-radius: 0;
}

.app-po-line:nth-child(odd) .po-line-content {
  border-left:6px #ffffff solid;
  border-radius: 0;

}

/* VENDOR ADJUSTMENTS */
app-item-simple:first-child > div .tooltip {
  top:55px !important;
}

app-item-analytics:first-child > div .tooltip {
  top:35px !important;
}

app-item-detailed:first-child > div .tooltip:not(.tooltip-bottom) {
  top:65px !important;
}

app-item-simple:first-child > div .tooltip.moqdq {
  top:30px !important;
}

app-item-detailed:first-child > div .tooltip.moqdq {
  top:30px !important;
}

.items-action-buttons pagination .page-link {
  padding: .3rem .55rem !important;
}

/* TOOLTIP ADJUSTMENTS CATA0LOG */
app-item-list > div.search-results > div > div:nth-child(1) .wrapper .tooltip.moq-tooltip {
  width:auto !important;
  bottom:-70px !important;
  left: 50% !important;
  margin-left:-90px !important;
  padding: 12px 16px;
  background: #000000 !important;
  border: 1px solid rgba(51,51,51,0.30);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
}

/* #buyer-home-body > div > div.col.buyer-home-main.withPrecartOpened > app-item-list > div.search-results > div > div:nth-child(1) > app-item > div > div.item__main > div > div.col.item__main--right.hide-mobile > div > div.col.item__price.ng-star-inserted > div.wrapper > div.tooltip.moq-tooltip */

app-item-list > div.search-results > div > div:nth-child(1) .wrapper .tooltip.moq-tooltip span {
  white-space: nowrap !important;
}

app-item-list > div.search-results > div > div:nth-child(1) .wrapper .tooltip.moq-tooltip:after {
  border-left: solid transparent 8px !important;
  border-right: solid transparent 8px !important;
  border-bottom: solid rgba(51,51,51,0.20) 7px !important;
  border-top:none!important;
  left: 50% !important;
  top:-7px !important;
  margin-left: -11px !important;
  z-index: 1;
}
app-item-list > div.search-results > div > div:nth-child(1) .wrapper .tooltip.moq-tooltip:before {
  border-left: solid transparent 6px !important;
  border-right: solid transparent 6px !important;
  border-bottom: solid #000000 6px !important;
  border-top: none !important;
  top: -6px !important;
  content: "";
  height: 0;
  left: 50% !important;
  margin-left: -9px !important;
  position: absolute;
  width: 0;
  z-index: 2;
}

.flipped-img{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
  filter: FlipH;
  -ms-filter: "FlipH";
}

.items-list .app-pending-sold-items:first-child .tooltip.white.date {
  width: 150px;
  left: 120px;
  bottom:-60px;
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid rgba(51,51,51,0.30);
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.items-list .app-pending-sold-items:first-child .tooltip.white.date:after {
  border-width:0 10px 10px 10px !important;
  border-bottom-color:#000000;
  border-style: solid !important;
  content:"";
  margin-left:-6px !important;
  position: absolute !important;
  top:-10px !important;
}

.items-list .app-pending-sold-items:first-child .tooltip.white.date:before {
border-width:0 10px 10px 10px !important;
border-bottom-color:#FFFFFF;
border-style: solid !important;
content:"";
margin-left:-6px !important;
position: absolute !important;
top:-10px !important;
}


/* ITEM ATTRIBUTES - ICONS */
.purple svg g, .purple path {
  fill:#FFFFFF !important;
  opacity: 1 !important;
}
/*left nav scrolling*/
.left-nav .ps__rail-y{
  z-index: 999;
}
.scrollable-content{
  max-width: 100%!important;
}

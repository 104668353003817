@import './assets/partials/_fonts.scss';

/* OFFICIAL FONTS -------------------------------------->>> */
$bold: 'HelveticaNeueCyr-Bold';
$medium: 'HelveticaNeueCyr-Medium';
$normal: 'HelveticaNeueCyr-Roman1';
/* <<<-------------------------------------- END OFFICIAL FONTS */

/* OFFICIAL COLORS -------------------------------------->>> */
$primary:           #1A1A1A;


$primaryHover:      #42357F;
$primaryHoverLogin: #5B4BA5;
$vendorLeftNavActive: #41357e;

$secondary:      #EB3B44;
$secondaryHover: #FF8282;

$blue: #4687D6;
$green: #80CB5E;
$red: #EB3B44;

$darkText: #1A1A1A;
$whiteText: rgba(255, 255, 255, 0.8);

$textColor: #000000;
/* <<<-------------------------------------- END OFFICIAL COLORS */

/* ADDITONAL COLORS */
$cancelError:    #ED2C31;
$warning:        #D90000;
$alertBackCart:  #FDE1E2;

$itemPriceDrop:  #F9FDF7;

$borderOddEven:  #F8F8F8;
$border2:        #e4e9f2;

$priceDrop:      #8FD96D; /* Tooltip */

$error: #FF5757; /* cart attr value */
$vendorError: #E2574C;
$vendorManufacturerLogo: #403482;
$pendingSold: #FFB822;
/* END ADDITIONAL COLORS */

/* OTHER COLORS MIXINS -------------------------------------->>> */
@mixin whiteRGBA($opacity) {
  color: rgba(255,255,255, $opacity);
}

@mixin whiteBgRGBA($opacity) {
  background: rgba(255, 255, 255, $opacity);
}
/* <<<-------------------------------------- END OTHER COLORS MIXINS */

/* ADDITIONAL DETAILS -------------------------------------->>> */
  $opacity05: 0.5;
  $opacityDisabled: 0.3;
/* <<<-------------------------------------- END ADDITIONAL DETAILS */


/* SEMI-GLOBAL MIXINS -------------------------------------->>> */
@mixin btn() {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font: 14px/20px $bold;
  color: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  &:disabled,
  &.disabled {
    opacity: .5;
  }
}

@mixin input() {
  display: block;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 0 16px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  outline: 0;
  height: 40px;
  font: 14px/40px $normal;
  border: none;
  &.error {
    border: 1px solid $red;
  }
}
@mixin textarea() {
  width: 100%;
  resize: none;
  color: #000000;
  letter-spacing: 0;
  font: 14px/20px $normal;
  padding: 6px 12px 0;
  border: none;
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.18);
  border-radius: 8px;

  &::-webkit-input-placeholder {
    color: rgba(#000, .4);
  }
  &::-moz-placeholder {
    color: rgba(#000, .4);
  }
  &:-ms-input-placeholder {
    color: rgba(#000, .4);
  }
  &:-moz-placeholder {
    color: rgba(#000, .4);
  }
}

@mixin modal() {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: rgba(0, 0, 0, .6);
  padding: 0 16px;
}

@mixin close() {
  position:absolute;
  width:24px;
  height:24px;
  background:url('./assets/images/close.svg') no-repeat;
  display: block;
  cursor:pointer;
  top: 0;
  right: 0;
  z-index: 3;
}

@mixin regularA() {
  font: 14px/17px $bold;
  color: $blue;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
}

@mixin redGradient() {
  background-image: linear-gradient(225deg, #FE563B 0%, #D8214D 100%);
}

@mixin blueGradient() {
  background-image: linear-gradient(225deg, #519DFA 0%, #2070D2 100%);
}

@mixin animated($speed) {
    -webkit-animation-duration: $speed !important;
    animation-duration: $speed !important;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@mixin flipHorizontal($scaleX) {
    -webkit-filter: FlipH;
    filter: FlipH;
    -ms-filter: "FlipH";
    transform: scaleX($scaleX);
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg) !important;
  -moz-transform: rotate($deg) !important;
  -o-transform: rotate($deg) !important;
  -ms-transform: rotate($deg) !important;
  transform: rotate($deg) !important;
}

@mixin borderRadius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin borderRadiusTopRight($radius) {
  -webkit-border-radius: $radius 0 0 $radius;
  -moz-border-radius: $radius 0 0 $radius;
  border-radius: $radius 0 0 $radius;
}

@mixin borderRadiusTopBottomLeft($top, $bottom, $left) {
  border-top: $top solid transparent;
  border-bottom: $bottom solid transparent;
  border-left: $left solid $primary;
}

@mixin borderLeftRightTop($left, $right, $top, $topColor) {
  border-left: solid transparent $left;
  border-right: solid transparent $right;
  border-top: solid $topColor $top;
}

@mixin hideMobile() {
  .hide-mobile {
    display: none;
  }
}

@mixin hideDesktop() {
  .hide-desktop {
    display: block;
  }
}

@mixin modalBackdrop($opacity) {
  -webkit-transition: opacity $opacity, visibility $opacity;
  -moz-transition: opacity $opacity, visibility $opacity;
  transition: opacity $opacity, visibility $opacity;
}

@mixin noBoxShadow() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin itemLogoBackProperties() {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}

@mixin tooltipBoxShadowTransitions() {
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
    transform: translateY(10px);
-webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

@mixin flex($display, $justifyContent, $alignItems) {
  display: $display;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flex2($display, $alignContent, $justifyContent) {
  display: $display;
  align-content: $alignContent;
  justify-content: $justifyContent;
}

@mixin flex3($display, $flexDirection, $flexWrap, $alignItems) {
  display: $display;
  flex-direction: $flexDirection;
	flex-wrap: $flexWrap;
  align-items: $alignItems;
}

@mixin flex4($display, $alignItems) {
  display: $display;
  align-items: $alignItems;
}

@mixin flex5($display, $flexDirection, $justifyContent, $alignItems) {
  display: $display;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin backgroundProperties($repeat, $size, $position) {
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}

@mixin checkbox {
  display: inline-flex;
  vertical-align: top;
  position: relative;
  min-width: 24px;
  min-height: 24px;
  margin: 0;
  cursor: pointer;

  ._icon {
    width: 24px;
    height: 24px;
    background-image: url(assets/images/icons/checkbox/unselected.svg);

    & + ._label {
      margin-left: 10px;
    }
  }

  ._label {
    font: 14px/24px $bold;
    color: rgba(#fff, .6);

    & + ._icon {
      margin-left: 10px;
    }
  }

  &:hover {
    input:not(:checked):not(:indeterminate) ~ ._icon {
      background-image: url(assets/images/icons/checkbox/unselected-hover.svg);
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;

    &:checked ~ ._icon {
      background-image: url(assets/images/icons/checkbox/selected.svg);
    }
    &:indeterminate ~ ._icon {
      background-image: url(assets/images/icons/checkbox/partially-selected.svg);
    }
  }
}
/* <<<-------------------------------------- END SEMI-GLOBAL MIXINS */

/* BORDER MIXINS -------------------------------------->>> */
@mixin border1() {
  border:1px #E7E7E7 solid;
}

@mixin border2() {
  border:1px #e4e9f2 solid;
}

@mixin border3() {
  border:1px #E0E0E0 solid;
}

@mixin border4() {
  border:1px #C1C1C1 solid !important;
}

@mixin warningBorder() {
  border:1px #FFC1C1 solid !important;
}

@mixin termsBorder() {
  border-top:2px #CECECE solid;
}

/* Used in cart component, forgot password component, login component, main-top component and po full details component */
@mixin border5() {
  border:1px #AAAAAA solid;
}
/* <<<-------------------------------------- END BORDER MIXINS */

/* GLOBAL RESETS -------------------------------------->>> */
@mixin ulLiReset() {
  list-style:none;
  margin:0;
  padding:0;
}

@mixin paddingAndMarginReset() {
  padding:0;
  margin:0;
}

@mixin noOutline() {
  outline:none !important;
  box-shadow: none !important;
}
/* <<<-------------------------------------- END GLOBAL RESETS */

/* GLOBAL STYLES -------------------------------------->>> */
html:not(.mobile){
  &,body {
    min-width: 1000px;
    height: 100vh;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    font: 14px/20px $normal;
    position: relative;
    top: 0;
    transition: top 0.3s ease-out;
  }

  &.gg-modal {
    .buyer-home-body {
      z-index: unset;
    }
  }
}

html.mobile {
  width: 100vw;
  height: 100vh;
  &, body {
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
  }

  body {
    padding-bottom: 52px;
  }
  &.is-modal {
    &, body {
      height: 100vh;
      width: 100%;
      overflow: hidden!important;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      -webkit-overflow-scrolling: auto;

      .user-page-wrapper.mobile {
        .user-content-wrapper {
          overflow: visible;
        }
      }
      .main-cart-wrap.mobile {
        overflow: visible;
      }
    }
    &.gg-modal {
      .btm-nav-wrap {
        z-index: 1;
      }
      .buyer-home-body.mobile {
        overflow: visible;
      }
    }
  }

  svg {
    pointer-events: none;
  }

  .loader {
    position: fixed;
  }
}
.loader-wrapper{
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 200;
  left: 0;
  top: 0;
  padding-left: 260px;
  >.loader{
    position: relative;
    top: auto;
    left: auto;
  }
}

.cart-loader {
  @include borderRadius(50%);
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 4px -4px 0;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 99999999;
  background: url(/assets/images/spinner-blue.gif) no-repeat transparent center center;
  background-size: cover;
  &.bulk-loader {
    margin: 0 !important;
  }
  &.__white {
    margin: 0;
    margin-right: 4px;
    background: url(/assets/images/spinner-white.gif) no-repeat transparent center center;
    background-size: cover;
  }
}

.mobile-loader {
  @include borderRadius(50%);
  display: inline-block;
  width: 22px;
  height: 22px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 99999999;
  background: url(/assets/images/spinner.svg) no-repeat transparent center center;
  background-size: cover;
  display: block;
  position: absolute;
  top:8px;
  left:25px;
}

.price-list-loader {
  @include borderRadius(50%);
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0 4px -4px 0;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 99999999;
  background: url(/assets/images/spinner.svg) no-repeat transparent center center;
  background-size: cover;
}

.static-apps-loader {
  @include borderRadius(50%);
  // display: inline-block;
  position: absolute;
  top:12px;
  left:60px;
  width: 22px;
  height: 22px;
  margin: 0 4px -4px 0 !important;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 99999999;
  background: url(/assets/images/spinner.svg) no-repeat transparent center center;
  background-size: cover;
}

app-item-attributes-mobile{
  position:relative;
  width: 0;
  height: 24px;
}

.hidden-sm-down{
  @media screen and (max-width: 991px) {
    display: none!important;
  }
}
.hidden-sm-up{
  @media screen and (max-width: 991px) {
    display: block!important;
  }
}

.hide {
  display: none !important;
}

.helper {
  height:100%;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.cancel-error {
  color:$cancelError;
}

.enabled-row {
  color: #555555;
  cursor:pointer;
}

.disabled-row {
  color: grey;
  cursor: context-menu;
}

button:disabled {
  opacity: $opacityDisabled;
}

.item-disabled {
  opacity: $opacityDisabled;
}

input.disabled {
  background:#E4E2EE;
  cursor: context-menu;
}
/* This will be removed because we already have bootstrap ready clear */
.clear {
  clear:both;
}
/* Dashboard chart title */
text.highcharts-title{
  font-family: 'HelveticaNeueCyr-Bold';
}

// owl dots in mobile cart
.checkout-boxes {
  .owl {
    &-dots {
      text-align: center;
      -webkit-tap-highlight-color: transparent;
      margin-top: 10px;
    }
    &-dot {
      display: inline-block;
      zoom: 1;
      background: 0 0;
      color: inherit;
      border: none;
      padding: 0!important;
      font: inherit;
      span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #D6D6D6;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity .2s ease;
        border-radius: 30px;
      }
      &.active {
        span {
          background: #869791;
        }
      }
      &:hover {
        span {
          background: #869791;
        }
      }
    }
  }
}
// owl themes

.owl-faq-slider {
  .owl {
    &-theme {
      width: 100%;
      margin: 0;
      overflow: hidden;
      border-radius: 12px 12px 0 0;
    }
    &-nav {
      display: none;
    }
    &-stage {
      display: block;
    }
    &-dots {
      position: absolute;
      top: 358px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
    }
    &-dot {
      width: 6px;
      height: 6px;
      margin: 0 3px;
      span {
        display: block;
        width: 6px;
        height: 6px;
        background-color: rgba(#1A1A1A, .3);
        transition: background-color .3s;
        border-radius: 50%;
      }

      &.active {
        span {
          background-color: #1A1A1A;
        }
      }
    }
  }
  &.owl-live-offers-faq {
    .owl-dots {
      top: 348px;
    }
  }
}
.owl-user-filter-faq {
  .owl {
    &-theme {
      width: 100%;
      margin: 0;
      overflow: hidden;
      border-radius: 12px 12px 0 0;
    }
    &-nav {
      display: none;
    }
    &-stage {
      display: block;
    }
    &-dots {
      position: absolute;
      top: 263px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
    }
    &-dot {
      width: 14px;
      height: 14px;
      span {
        display: block;
        width: 6px;
        height: 6px;
        background-color: rgba(#fff, .3);
        transition: background-color .3s;
        border-radius: 50%;
      }

      &.active {
        span {
          background-color: #fff;
        }
      }
    }
  }
}
.auction-item-slider-wrap {
  .owl {
    &-theme {
      position: relative;
      height: 100%;
    }
    &-nav {
      position: absolute;
      left: 24px;
      right: 24px;
      bottom: 24px;
      background-color: #FFFFFF;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      display: flex;
      justify-content: flex-end;
    }

    &-prev, &-next {
      margin: 0 4px;
      width: 40px;
      height: 40px;
      border: none;
      background-color: transparent;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        width: 24px;
        height: 24px;
        font-size: 0;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        background-size: 24px 24px;
      }
    }

    &-prev {
      span {
        background-image: url("/assets/images/icons/auctions/slider-arrow-left.svg");
      }
    }

    &-next {
      span {
        background-image: url("/assets/images/icons/auctions/slider-arrow-right.svg");
      }
    }
  }
}
.auctions-slider-wrap {
 .center {
    .auction-item {
      opacity: 1!important;
      pointer-events: auto !important;
      &__img, &__info {
        cursor: pointer;
      }
    }
  }
  .owl {

    &-theme {
      position: relative;
      margin: 0 36px;
      width: calc(100% - 72px);
    }
      &-stage-outer {
        overflow: visible;
      }

      &-nav {
        margin: 0;
      }

      &-dots {
        display: none;
      }

      &-prev, &-next {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        margin: -20px 0 0;
        background-color: #FFFFFF;
        border-radius: 40px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
        padding: 8px;
        font-size: 0;
        line-height: 0;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-repeat: no-repeat;
          background-color: transparent;
          background-position: center center;
          background-size: 24px 24px;
        }

        span {
          display: none;
        }
      }

      &-prev {
        left: 50%;
        margin-left: -441px;

        &:before {
          background-image: url(/assets/images/icons/auctions/slider-arrow-left.svg);
        }
      }
      &-next {
        right: 50%;
        margin-right: -441px;

        &:before {
          background-image: url(/assets/images/icons/auctions/slider-arrow-right.svg);
        }
      }
  }
  &.mobile {
    .owl {
      &-theme {
        width: 100%;
        margin: 0;
      }
      &-nav {
        display: none;
      }
      &-dots {
        margin-top: 13px;
        display: flex;
        justify-content: center;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
      }
      &-dot {
        display: inline-block;
        zoom: 1;
        span {
          display: block;
          margin: 4px;
          width: 6px;
          height: 6px;
          background-color: rgba(#fff, .3);
          transition: background-color .3s;
        }

        &.active {
          span {
            background-color: #fff;
          }
        }
      }
    }
  }
}
/* <<<-------------------------------------- END GLOBAL STYLES */

/* MIXINS SHARED CODE / FROM CART -------------------------------------->>> */
@mixin modalSharedProperties() {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 54, 66, 0.5);
  z-index: 9999;
  overflow-y: auto;
  @include modalBackdrop(0.3s);
}
@mixin modalSharedProperties2() {
  width:24px;
  height:24px;
  background:url(/assets/images/close.svg) no-repeat;
  display: block;
  cursor:pointer;
}
@mixin addressModalShared() {
  background-size: contain;
  height: 31px;
  width: 31px;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.oddEven app-pre-cart-child:nth-child(even) .ok {
  background:#FFFFFF !important;
}
/* <<<-------------------------------------- END MIXINS SHARED CODE / FROM CART */

@media (print), (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

/* <<<-------------------------------------- VENDOR DATEPICKER */
.theme-blue {
  &.bs-datepicker {
    background: none;
    box-shadow: none;
    .bs-datepicker-multiple {
      max-width: 252px;
    }
    .bs-datepicker-container {
      padding: 24px 32px 0 24px;
    }
    .bs-datepicker-head {
        background-color: #fff;
        button {
          color: #000000;
          &:hover{
            background-color: transparent;
          }
        }
    }
    .bs-datepicker-body {
      border-radius: 0;
      min-height: 236px;
      min-width: 252px;
      border: none;
      padding: 0;
      table {
        th {
          font: 12px/18px $normal;
          color: #000000;
          opacity: .5;
        }
        td {
          font: 14px/16px $normal;
          color: #000000;
          &:before {
            content: none;;
          }
          span {
            min-width: 36px;
            width: 100%;
            height: 36px;
            line-height: 36px;
            margin: 0;
            &:before {
              content: none;;
            }
            &.is-other-month {
              display: none;
            }
            &.selected {
              background: #1A1A1A;
              border-radius: 8px;
            }
            &.is-highlighted {
              border-radius: 8px;
              background: #F6F6F6;
            }
            &.in-range:not(.selected) {
              border-radius: 0;
              background: #F6F6F6;
            }
          }
        }
      }
    }
  }
}

.theme-calendar {
  &.bs-datepicker {
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.25);
    border-radius: 8px;

    .bs-datepicker {
      &-container {
        padding: 16px;
      }
      &-head {
        height: 32px;
        padding: 0;
        button {
          color: #000;
          height: 32px;
          line-height: 32px;
          border-radius: 8px;
          transition: none;

          &:hover {
            background-color: #F6F6F6;
          }

          &.previous, &.next {
            width: 24px;
            height: 24px;
            margin: 4px 0;

            span {
              display: block;
              width: 24px;
              font-size: 0;
              background-position: center center;
            }
          }

          &.previous {
            span {
              background-image:url(/assets/images/icons/arrow-left-gray.svg);
            }
          }
          &.next {
            span {
              transform: rotate(180deg);
              background-image:url(/assets/images/icons/arrow-left-gray.svg);
            }
          }
        }
      }
      &-body {
        margin-top: 10px;
        border-radius: 0;
        min-height: 236px;
        min-width: 252px;
        border: none;
        padding: 0;
        table {
          th {
            font: 12px/18px $normal;
            color: #000000;
            opacity: .5;
          }
          td {
            font: 14px/16px $normal;
            color: #000000;
            &:before {
              content: none;
            }
            &.is-highlighted:not(.disabled):not(.selected) span {
              background-color: #F6F6F6;
            }
            span {
              min-width: 36px;
              width: 100%;
              height: 36px;
              line-height: 36px;
              margin: 0;
              padding: 0;
              border-radius: 8px;
              &:before {
                content: none;
              }
              &.is-other-month {
                display: none;
              }
              &.selected {
                background: #1A1A1A;
                border-radius: 8px;
              }
              &.is-highlighted:not(.disabled):not(.selected) {
                border-radius: 8px;
                background-color: #F6F6F6;
              }
              &.in-range:not(.selected) {
                border-radius: 0;
                background: #F6F6F6;
              }
            }
          }
        }
      }
    }
  }
}

// Product listings vendor Pagination
.items-bottom-controls,
.orders-bottom-controls,
.notification-center-bottom-controls,
.notification-bottom-controls {
  .pagination {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    justify-content: flex-start;
    .page {
      &-item {
        &.active {
          .page-link {
            border: 1px solid #4586D5;
            border-radius: 8px;
            color: #1A1A1A;
            background: #FFFFFF;
          }
        }
        &.disabled {
          .page-link {
            opacity: .5;
          }
        }

      }
      &-link {
        position: relative;
        display: block;
        font: 14px/20px $normal;
        color: #1A1A1A;
        border: none;
        &:hover {
          color: #1A1A1A;
          background-color: #FFFFFF;
        }
      }
    }
    .pagination-page {
      .page-link {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .pagination-next,
    .pagination-prev,
    .pagination-first,
    .pagination-last {
      .page-link {
        font-family: $bold;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

// .theme-blue .bs-datepicker-body table td span.selected, .theme-blue .bs-datepicker-body table td.selected span,
// .theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
// .theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
//   background-color:#493B8D !important;
// }
/* <<<-------------------------------------- VENDOR DATEPICKER END */


a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;
    box-shadow: 0 0 0 0 rgba(0,123,255,0);
}

.pac-container {
  z-index: 100000 !important;

  &:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
    background-image: none !important;
    height: 0px;
  }
}
